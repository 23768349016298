import * as React from "react";
import "./style.scss";
import {useContext, useEffect, useState} from "react";
import SiteContext from "context/SiteContext";


const LangBar = () => {
  const site = useContext(SiteContext);

  const getClasses = (code) => {
    const list = [
      "lang-bar-lang",
      `lang-bar-lang-${code}`,
      code === site.lang ? "lang-bar-lang-active" : "lang-bar-lang-passive"
    ]
    return list.join(' ');
  }

  const languages = [
    {
      "url": "/",
      "label": "TR",
      "classes": getClasses('tr')
    },
    {
      "url": "/en",
      "label": "EN",
      "classes": getClasses('en')
    }
  ]

  return <div className={`lang-bar-container`}>
    <div className="lang-bar">
      {
        languages.map(language => (
          <div className={language.classes} key={language.label}>
            <a href={language.url} style={{ textDecoration: 'none' }}>{language.label}</a>
          </div>
        ))
      }
    </div>
  </div>
}

export default LangBar;