import * as React from "react";
import "./style.scss";
import Badges from "components/Badges";
import {useContext, useEffect, useState} from "react";
import SiteContext from "context/SiteContext";


const StickyBar = () => {
  const site = useContext(SiteContext);
  const [device, setDevice] = useState("unknown");

  useEffect(() => {
    const getDevice = () => {
      if (navigator.userAgent.toLowerCase().indexOf("huawei") >= 0) return "appgallery";
      if (navigator.userAgent.toLowerCase().indexOf("android") >= 0) return "playstore";
      return "appstore";
    }
    setDevice(getDevice());
    setupScrollListener();
  }, []);

  const setupScrollListener = () => {
    const callback = (entries) => {
      const bar = document.querySelector("#sticky-bar-container");
      const switchTo = pos => {
        bar.classList.remove('sticky-bar-container--pos1');
        bar.classList.remove('sticky-bar-container--pos2');
        bar.classList.remove('sticky-bar-container--pos3');
        bar.classList.remove('sticky-bar-container--pos4');
        bar.classList.add(`sticky-bar-container--${pos}`);
      }

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.id === "section-3" && entry.boundingClientRect.y > 0) switchTo('pos2');
          else if (entry.target.id === "section-6" && entry.boundingClientRect.y > 0) switchTo('pos3');
          else if (entry.target.id === "footer" && entry.boundingClientRect.y > 0) switchTo('pos4');
          else if (entry.target.id === "section-4" && entry.boundingClientRect.y < 0) switchTo('pos2')
          else if (entry.target.id === "section-1" && entry.boundingClientRect.y < 0) switchTo('pos1');
        } else {
          if (entry.target.id === "footer") switchTo("pos3");
        }
      });
    };

    let observer = new IntersectionObserver(callback);
    observer.observe(document.querySelector('#section-1'));
    observer.observe(document.querySelector('#section-3'));
    observer.observe(document.querySelector('#section-4'));
    observer.observe(document.querySelector('#section-6'));
    observer.observe(document.querySelector('#footer'));
  }


  return <div id="sticky-bar-container" className={`sticky-bar-container sticky-bar-container--pos1 sticky-bar-container--${device}`}>
    <div className="sticky-bar">
      <div className="sticky-bar-text">
        <strong>{site.lang === "tr" ? "Şimdi indir" : "Install now"}</strong>!
        <br />
        <strong>{site.lang === "tr" ? "30 gün ücretsiz dene" : "Try free for 30 days"}</strong>!
      </div>
      <Badges/>
    </div>
  </div>
}

export default StickyBar;
