import * as React from "react"
import './style.scss';

export type SectionBg = "white" | "dark-gradient" | "dark-solid" | "light" | "dark"

interface Props {
  id: string
  bg: SectionBg
  children: React.ReactNode,
  imgLeft?: boolean
  image?: string
}

const Section = ({id, bg, children, imgLeft, image}: Props) => {
  const imageFirst = imgLeft ? ' section-container--imagefirst' : '';

  const imgTag = image ?
    <div className="section-image">
      <img alt="" src={image}/>
    </div> : null;

  const bodyTag = <div className="section">
    {children}
  </div>;

  if (imgLeft) {
    return <div id={`section-${id}`} className={`section-container section-container-${bg}${imageFirst}`}>
      {imgTag}
      {bodyTag}
    </div>
  }

  return <div id={`section-${id}`} className={`section-container section-container-${bg}${imageFirst}`}>
    {bodyTag}
    {imgTag}
  </div>
}

Section.defaultProps = {
  isImageFirst: false
}

export default Section;