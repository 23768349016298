import * as React from "react";
import './style.scss'

const BgCircle = () => {
  return <div className="bg-circle-container">
    <div className="bg-circle bg-circle--first"/>
    <div className="bg-circle bg-circle--second"/>
    <div className="bg-circle bg-circle--third"/>
  </div>
}

export default BgCircle;